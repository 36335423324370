<template>
  <div class="start_pay">
    <div class="title_blue" style="margin-top: 6.25rem;margin-bottom: 5.625rem">
      开始缴费
    </div>
    <div class="small_title" style="text-align: center;font-size: 1.875rem">订单金额（人民币）</div>
    <div class="small_title" style="text-align: center;font-size: 3.125rem;margin-top: 3.125rem">{{this.state.to_pay_type===1?this.$store.getters.getTotalPrice:this.orderTotalPrice}}</div>
    <div class="time_box">
      <div class="item">
        <div class="time_title">剩余支付时间</div>
        <div class="time">{{surplus_time_str}}</div>
      </div>
    </div>
    <div class="time_box" style="margin-top: 1.875rem">
      <div class="item">
        <div>缴费金额</div>
        <div>{{state.to_pay_type===1?this.$store.state.price:this.price}}{{state.to_pay_type===1?this.$store.getters.getCurrencyName:this.orderCurrencyName}}</div>
      </div>
      <div class="item">
        <div>汇率</div>
        <div>1{{state.to_pay_type===1? this.$store.getters.getCurrencyCode:this.orderCurrencyCode}}={{state.to_pay_type===1?this.$store.getters.getCurrencyRate:this.orderCurrencyRate}} CNY</div>
      </div>
      <div class="item">
        <div>平台服务费</div>
        <div>{{state.to_pay_type===1?this.$store.state.serve_price:this.serve_price}}人民币</div>
      </div>
      <div class="item">
        <div>足额到账费</div>
        <div>{{state.to_pay_type===1?this.$store.state.full_price:this.full_price}}人民币</div>
      </div>
      <div class="item">
        <div>订单号</div>
        <div>{{order_no}}</div>
      </div>
    </div>
    <el-tabs v-model="pay_way" class="tabs" @tab-click="handleClick">
      <el-tab-pane label="银行转账" name="1">
        <div class="tabs_item">
          <div style="display: flex;flex-direction: row;justify-content: space-between;">
            <div class="left" style="border: none">
              <div class="left_title">转账信息</div>
              <div class="left_small_title">受央行监管的备付金账户，资金安全有保障</div>
              <div class="time_box" style="font-size: 1.375rem">
                <div class="item">
                  <div class="time_title">转账截止时间</div>
                  <div class="time">{{effective_time_str}}</div>
                </div>
              </div>
              <div class="time_box" style="font-size: 1.375rem;margin-top: 1.375rem">
                <div class="item">
                  <div>收款户名</div>
                  <div>广州合利宝支付科技有限公司</div>
                </div>
                <div class="item">
                  <div>收款账号</div>
                  <div>{{yl_account_num}}</div>
                </div>
                <div class="item">
                  <div>收款银行</div>
                  <div>支付机构备付金集中存管账户</div>
                </div>
                <div class="item">
                  <div>开户地</div>
                  <div>广州市</div>
                </div>
                <div class="item">
                  <div>开户网点</div>
                  <div>广州合利宝-备付金账户</div>
                </div>
              </div>
              <div class="info">
                <div>查看</div>&nbsp;&nbsp;
                <a style="color: #008cd6;cursor: pointer" target="_blank" :href="zhiyin">转账指引</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a style="color: #008cd6;cursor: pointer" target="_blank" :href="zhuanzhang">各银行大额转账注意点</a>
              </div>
            </div>
            <div class="right">
              <div class="right_title">留学e汇订单</div>
              <div class="right_price">
                <div style="font-size: 1.5625rem">￥</div>
                <div style="font-size: 3.75rem;font-weight: bold">{{this.state.to_pay_type===1?this.$store.getters.getTotalPrice:this.orderTotalPrice}}</div>
              </div>
              <!--            <div class="right_price_tips">-->
              <!--              (含支付手续费 <span style="color: #d0392a">￥50.00</span>)-->
              <!--            </div>-->
              <div class="item">
                <div class="left">收款方</div>
                <div class="right">广州合利宝支付科技有限公司</div>
              </div>
              <div class="item">
                <div class="left">下单时间</div>
                <div class="right">{{create_time_str}}</div>
              </div>
              <div class="item">
                <div class="left">订单号</div>
                <div class="right">{{order_no}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="微信支付" name="2">
        <div class="tabs_item">
          <img src="../assets/weixin_pay_icon.png" style="width: 150px;display: block;margin: 0 auto 50px;" alt="">
          <div style="display: flex;flex-direction: row;justify-content: space-between;">
            <div class="left">
              <div class="left_small_title">订单将在{{surplus_time_str}}后关闭，请及时付款</div>
              <VueQr v-if="qrcode" class="qrcode" :margin='qr_margin' :size='qr_size' :whiteMargin="true" :text='qrcode'> </VueQr>
              <!--            <img  :src="qrcode" alt="">-->
              <div class="info">
                <div>请使用微信扫一扫完成支付</div>
              </div>
            </div>
            <div class="right">
              <div class="right_title">留学e汇订单</div>
              <div class="right_price">
                <div style="font-size: 1.5625rem">￥</div>
                <div style="font-size: 3.75rem;font-weight: bold">{{this.state.to_pay_type===1?this.$store.getters.getTotalPrice:this.orderTotalPrice}}</div>
              </div>
              <!--            <div class="right_price_tips">-->
              <!--              (含支付手续费 <span style="color: #d0392a">￥1390.04</span>)-->
              <!--            </div>-->
              <div class="item">
                <div class="left">收款方</div>
                <div class="right">广州合利宝支付科技有限公司</div>
              </div>
              <div class="item">
                <div class="left">下单时间</div>
                <div class="right">{{create_time_str}}</div>
              </div>
              <div class="item">
                <div class="left">订单号</div>
                <div class="right">{{order_no}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="支付宝支付" name="3">
        <div class="tabs_item">
          <img src="../assets/zhifu_pay_icon.png" style="width: 150px;display: block;margin: 0 auto 50px;" alt="">
          <div style="display: flex;flex-direction: row;justify-content: space-between;">
            <div class="left">
              <div class="left_small_title">订单将在{{surplus_time_str}}后关闭，请及时付款</div>
              <VueQr v-if="qrcode" class="qrcode" :margin='qr_margin' :size='qr_size' :whiteMargin="true" :text='qrcode'> </VueQr>
              <!--            <img class="qrcode" :src="qrcode" alt="">-->
              <div class="info">
                <div>请使用支付宝扫一扫完成支付</div>
              </div>
            </div>
            <div class="right">
              <div class="right_title">留学e汇订单</div>
              <div class="right_price">
                <div style="font-size: 1.5625rem">￥</div>
                <div style="font-size: 3.75rem;font-weight: bold">{{this.state.to_pay_type===1?this.$store.getters.getTotalPrice:this.orderTotalPrice}}</div>
              </div>
              <!--            <div class="right_price_tips">-->
              <!--              (含支付手续费 <span style="color: #d0392a">￥1390.04</span>)-->
              <!--            </div>-->
              <div class="item">
                <div class="left">收款方</div>
                <div class="right">广州合利宝支付科技有限公司</div>
              </div>
              <div class="item">
                <div class="left">下单时间</div>
                <div class="right">{{create_time_str}}</div>
              </div>
              <div class="item">
                <div class="left">订单号</div>
                <div class="right">{{order_no}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {base, req} from "@/apis/http";
import {timestampToTime, timeToStr} from "@/lib";
import VueQr from 'vue-qr'

export default {
  name: "FourTpl",
  components:{
    VueQr
  },
  data(){
    return{
      qr_size:280,
      qr_margin:8,
      stu_info_id:'',
      select_college_name:'',
      price:'',
      serve_price:'',
      full_price:'',
      pay_way:'1',
      create_time:'',
      create_time_str:'',
      effective_time:'',
      effective_time_str:'',
      //剩余时间
      surplus_time:'',
      surplus_time_str:'',
      order_no:'',
      qrcode:'',
      intervalTime:'',
      time_interval:'',
      orderAccountNum:'',
      orderCurrencyName:'',
      orderCurrencyCode:'',
      orderCurrencyRate:'',
      orderTotalPrice:'',
      state:this.$store.state,
      zhiyin:base+'/assets/static/img/zhuanzhangzhiyin.jpg',
      zhuanzhang:base + '/assets/static/img/daezhuanzhang.jpg',
      yl_account_num:''
    }
  },
  methods:{
    addOrder(){
      let that = this
      let {state,getters} = this.$store
      if (this.time_interval){
        clearInterval(this.time_interval)
      }

      //下单
      req('post','/api/order/toPay',{
        stu_info_id:state.stu_info_id,
        college_name:state.to_pay_type === 1 ? state.select_college_name : this.select_college_name,
        account_num: state.to_pay_type === 1 ? getters.getAccountNum : this.orderAccountNum,
        currency_name:state.to_pay_type === 1 ? getters.getCurrencyName : this.orderCurrencyName,
        currency_code:state.to_pay_type === 1 ? getters.getCurrencyCode : this.orderCurrencyCode,
        currency_rate:state.to_pay_type === 1 ? getters.getCurrencyRate : this.orderCurrencyRate,
        remit_amount:state.to_pay_type === 1 ? state.price : this.price,
        platform_fee:state.to_pay_type === 1 ? state.serve_price : this.serve_price,
        fully_rec_fee:state.to_pay_type === 1 ? state.full_price : this.full_price,
        total_amount:state.to_pay_type === 1 ? getters.getTotalPrice : this.orderTotalPrice,
        pay_way:this.pay_way
      }).then(res=>{
        if (res.code===1){
          this.create_time = res.data.create_time
          this.create_time_str = timestampToTime(res.data.create_time)
          this.effective_time = res.data.effective_time
          this.effective_time_str = timestampToTime(res.data.effective_time)
          this.order_no = res.data.order_no
          this.qrcode = res.data.qrcode
          this.surplus_time = res.data.effective_time-(Date.parse(new Date()) / 1000)
          this.surplus_time_str = timeToStr(res.data.effective_time-Math.floor((Date.parse(new Date()) / 1000))-1)
          this.yl_account_num = res.data.yl_account_num
          that.getTimePerSecond()
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    getTimePerSecond(){
       this.time_interval = setInterval(() => {
        let current_time = Date.parse( new Date() ).toString();//获取到毫秒的时间戳，精确到毫秒
        current_time = current_time.substring(0,10);//精确到秒
        let seconds = Number(this.effective_time)-Number(current_time)

        if (Number(seconds)<0){
          clearInterval(this.time_interval)
          this.surplus_time_str = '支付超时'
        }else{
          if (this.order_no){
            //查询订单支付状态
            req('get','/api/order/payStatus?order_no='+this.order_no,{order_no:this.order_no}).then(res=>{
              if (res.code===1){
                if (res.data.pay_status===2){
                  this.$message.warning('您已取消订单')
                  this.effective_time = 0
                }else if (res.data.pay_status===1){
                  this.$message.success('订单支付成功')
                  this.effective_time = 0
                  this.$store.dispatch('StuInfoId',{stu_info_id:''})
                  this.$router.replace('/order')
                }
              }
            })
          }
          this.surplus_time_str = timeToStr(seconds)
        }
      },1000)

    },
    handleClick(tab){
      this.pay_way = tab.name
      this.addOrder()
    }
  },
  created() {
    window.scrollTo(0,0)
    let {state} = this.$store
    if (!state.stu_info_id){
      this.$router.push('/')
      return false
    }
    this.stu_info_id = state.stu_info_id
    //检查当前登录状态
    req('get','/api/token/check').then(res=>{
      if (res.code!==1){
        this.$router.push('/')
      }
    })
    if (state.to_pay_type!==1 && state.order_no){
      req('get','api/order/detail?order_no='+state.order_no,{}).then(res=>{
        if (res.code===1){
          let {data} = res
          this.select_college_name = data.college_name
          this.price = data.remit_amount
          this.serve_price = data.platform_fee
          this.full_price = data.fully_rec_fee
          this.orderAccountNum = data.account_num
          this.orderCurrencyName = data.currency_name
          this.orderCurrencyCode = data.currency_code
          this.orderCurrencyRate = data.currency_rate
          this.orderTotalPrice = data.total_amount
          this.yl_account_num = data.yl_account_num

          this.addOrder()
        }else{
          this.$message.error(res.msg)
        }
      })
    }else{
      this.addOrder()
    }


  },
  beforeDestroy() {
    if (this.time_interval){
      clearInterval(this.time_interval)
    }
  }
}
</script>

<style scoped>

</style>