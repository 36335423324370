<template>
  <div class="footer">
    <div class="content">Copyright © 2022-现在 All Rights Reserved. <a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备2023006976号</a></div>
  </div>
</template>

<script>
export default {
  name: "FooterTpl"
}
</script>

<style scoped>
  .footer{
    width: 100%;
    height: 5rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
  }
  .footer .content{
    width: 90rem;
    text-align: center;
    color: #cccccc;
    margin: 0 auto;
  }
  .footer a{
    text-decoration: none;
    color: #cccccc;
  }
</style>