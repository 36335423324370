<template>
  <div class="step">
    <el-steps :active="$store.state.active" align-center>
      <el-step title="确认金额"></el-step>
      <el-step title="填写信息"></el-step>
      <el-step title="确认信息"></el-step>
      <el-step title="开始缴费"></el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
name: "StepsTpl"
}
</script>

<style scoped>

</style>