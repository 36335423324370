//对state数据进行加工
const getters = {
    //获取当前付款账户格式
    getAccountLength(state){
        return state.account_list.length
    },
    //需支付金额
    getTotalPrice(state){
        let {account_list,account_select_index} = state
        let currency_rate = account_list[account_select_index]['currency_rate']
        return (Number(state.price)*Number(currency_rate)+Number(state.full_price)+Number(state.serve_price)).toFixed(2)
    },

    getCurrencyCode(state){
        let {account_list,account_select_index} = state
        if (account_list!==''&&account_select_index!==''){
            return account_list[account_select_index]['currency_code']
        }else{
            return '';
        }

    },
    getCurrencyRate(state){
        let {account_list,account_select_index} = state
        if (account_list!==''&&account_select_index!==''){
            return account_list[account_select_index]['currency_rate']
        }else{
            return '';
        }
    },
    getCurrencyName(state){
        let {account_list,account_select_index} = state
        if (account_list!==''&&account_select_index!==''){
            return account_list[account_select_index]['currency_name']
        }else{
            return '';
        }
    },
    //收款账户名
    getAccountName(state){
        let {account_list,account_select_index} = state
        if (account_list!==''&&account_select_index!==''){
            return account_list[account_select_index]['account_name']
        }else{
            return '';
        }
    },
    //收款账户号
    getAccountNum(state){
        let {account_list,account_select_index} = state
        if (account_list!==''&&account_select_index!==''){
            return account_list[account_select_index]['account_num']
        }else{
            return '';
        }
    },
    //当前账户
    getCurrentAccount(state){
        let {account_list,account_select_index} = state
        if (account_list!==''&&account_select_index!==''){
            return account_list[account_select_index]
        }else{
            return [];
        }
    },
    //获取缴费通知单文件名称列表
    getFileTwoItem(state){
        let {file_list_two} = state
        let arr = []
        if (file_list_two.length>0){
            file_list_two.map(i=>{
                arr.push(i.file.name)
            })
        }
        return arr
    },
    getFileListOneJson(state){
        let {file_list_one} = state
        let arr = []
        if (file_list_one.length>0){
            file_list_one.map(i=>{
                arr.push(i.file_path)
            })
        }
        arr = JSON.stringify(arr)
        return arr
    },
    getFileListTwoJson(state){
        let {file_list_two} = state
        let arr = []
        if (file_list_two.length>0){
            file_list_two.map(i=>{
                arr.push(i.file_path)
            })
        }
        arr = JSON.stringify(arr)
        return arr
    },
    getFileListThreeJson(state){
        let {file_list_three} = state
        let arr = []
        if (file_list_three.length>0){
            file_list_three.map(i=>{
                arr.push(i.file_path)
            })
        }
        arr = JSON.stringify(arr)
        return arr
    },
    //获取图片个数1
    getFileNumOne(state){
        return state.file_list_one.length
    },
    getFileListOne(state){
        let {file_list_one} = state
        let arr = []
        if (file_list_one.length>0){
            file_list_one.map(i=>{
                arr.push({
                    name:i.file_name,
                    url:i.file_path
                })
            })
        }
        return arr
    }
}

export default getters