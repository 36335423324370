<template>
  <div class="header">
    <div class="header_center">
      <router-link to="/"> <img src="../assets/logo.png" class="logo" alt=""></router-link>
      <div class="right_btns">
        <a class="menu_item" href="http://www.gzlxeh.com" target="_blank">首页</a>
<!--        <router-link class="menu_item" to="/">留学缴费</router-link>-->
<!--        <router-link class="menu_item" to="/">帮助中心</router-link>-->
        <div v-if="!this.$store.state.token" class="login_btn" @click="toLogin">登录/注册</div>
        <div class="menu_item uu" v-if="this.$store.state.token"><i class="el-icon-user-solid" style="margin-right: 0.3125rem"></i>
          <div style="display: inline-block;margin-right: 0.3125rem" @click="changeMenusStatus">{{this.$store.state.username}}</div>
          <i v-if="showMenus" class="el-icon-caret-bottom"></i>
          <i v-if="!showMenus" class="el-icon-caret-top"></i>
          <div v-if="showMenus" class="uu_ul">
            <div class="uu_li" @click="toOrder"><i style="color: #008cd6;margin-right: 0.5rem" class="el-icon-user"></i>我的订单</div>
            <div class="uu_li" @click="logout"><i style="color: #008cd6;margin-right: 0.5rem" class="el-icon-switch-button"></i>退出登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderTpl",
  data() {
    return{
      showMenus:false
    }
  },
  methods:{
    toOrder(){
      this.$router.push('/order')
    },
    toLogin(){
      this.$store.dispatch('updateLoginCardStatusAction',true)
    },
    logout(){
      localStorage.clear();
      this.$store.dispatch('updateUserInfoAction',{token:'',username:'',mobile:'',id:''})
      this.$router.push("/")
    },
    changeMenusStatus(){
      if (this.showMenus===true){
        this.showMenus=false
      }else {
        this.showMenus=true
      }
    }
  },
  created() {
    let token = localStorage.getItem('token')
    let username = localStorage.getItem('username')
    let mobile = localStorage.getItem('mobile')
    let id = localStorage.getItem('id')
    this.$store.dispatch('updateUserInfoAction',{token:token,username:username,mobile:mobile,id:id})
  }
}
</script>

<style scoped>
  .uu{
    cursor: pointer;
    position: relative;
  }
  .uu .uu_ul{
    position: absolute;
    left: 1.875rem;
    width: 12.5rem;
    box-sizing: border-box;
    padding: 0.625rem 2.5rem;
    background: #ffffff;
    border-radius: 0.5rem;
    border: 0.0625rem solid #ccc;
  }
  .uu .uu_ul .uu_li{
    color: #000517;
  }
  .header{
    width: 100%;
    min-width: 61.25rem;
    height: 5rem;
    background: #008cd6;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }
  .header .header_center{
    width: 90rem;
    height: 5rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .header .header_center .logo{
    width: 11.875rem;
    height: 3.8rem;
    cursor: pointer;
  }
  .header .header_center .right_btns{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .header .header_center .right_btns .menu_item{
    height: 3.125rem;
    line-height: 3.125rem;
    color: #FFFFFF;
    font-size: 1.25rem;
    box-sizing: border-box;
    padding: 0rem 1.875rem;
  }
  .header .header_center .right_btns  .login_btn{
    height: 3.125rem;
    line-height: 3.125rem;
    color: #008cd6;
    font-size: 1.25rem;
    box-sizing: border-box;
    padding: 0rem 1.875rem;
    background: #FFFFFF;
    border-radius: 0.625rem;
    cursor: pointer;
  }
</style>