<template>
  <el-dialog
      title=""
      :visible.sync="this.$store.state.show_login_card"
      width="500px"
      :before-close="handleClose">
    <!--      内容-->
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
      <img style="width: 200px;margin: 0 auto;display: block;margin-top: -30px" src="../assets/blue_logo.png" alt="">
      <div class="login_title">手机号登录 / 注册</div>

      <div class="sendCode" style="display: flex;flex-direction: row;align-items: center;margin-bottom: 1.875rem">
        <el-form-item prop="phone">
          <el-input placeholder="请输入手机号" v-model="ruleForm.phone" class="input-with-select">
            <template slot="prepend">+86</template>
          </el-input>
        </el-form-item>
        <div style="margin-left: 0.625rem">
          <el-button @click="getVcode" v-if="could_send" type="primary" plain>短信验证</el-button>
          <el-button @click="getVcode" v-if="!could_send" disabled type="info" plain>{{send_btn}}</el-button>
        </div>
      </div>

      <el-form-item prop="vcode" style="margin-bottom: 1.875rem">
        <el-input placeholder="请填写验证码（5分钟内有效）" v-model="ruleForm.vcode"></el-input>
      </el-form-item>

      <el-form-item class="sub" style="margin-bottom: 1.25rem">
        <el-button type="primary" @click="submitForm('ruleForm')" style="width: 100%">登录&nbsp;/&nbsp;注册</el-button>
      </el-form-item>

      <div class="agree">登录即表示您同意 <a class="blue" :href="base" target="_blank">《留学e汇款隐私政策》</a></div>

    </el-form>

  </el-dialog>
</template>

<script>
import {req,base} from "@/apis/http";

export default {
  name: "LoginTpl",
  data(){
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('手机号码不能为空'));
      }else if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value)){
        callback(new Error('手机号码格式错误'));
      }else{
        callback()
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'));
      } else {
        callback();
      }
    };
    return {
      base:'',
      could_send:true,
      ruleForm: {
        phone:'',
        vcode: ''
      },
      rules: {
        phone: [
          { validator: validatePass, trigger: 'blur' }
        ],
        vcode: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      send_btn:'短信验证'
    };
  },
  methods:{
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          req('post','/api/user/login',{mobile:this.ruleForm.phone,code:this.ruleForm.vcode},false).then(res=>{
            if (res.code===1){
              localStorage.setItem('token',res.data.token)
              localStorage.setItem('username',res.data.username)
              localStorage.setItem('mobile',res.data.mobile)
              localStorage.setItem('id',res.data.id)
              this.$message.success(res.msg)
              this.$store.dispatch('updateUserInfoAction',{token:res.data.token,username:res.data.username,mobile:res.data.mobile,id:res.data.id})
              this.$store.dispatch('updateLoginCardStatusAction',false)
              location.reload()
            }else{
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleClose(){
      let token = localStorage.getItem('token')
      if (!token){
        return false
      }
      this.$store.dispatch('updateLoginCardStatusAction',false)
    },
    //获取验证码
    getVcode(){
      if (!this.could_send){
        return false;
      }
      if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.ruleForm.phone)){
        this.$message.error('手机号码格式错误')
        return false
      }
      req('post','/api/sms/send',{mobile:this.ruleForm.phone}).then(res=>{
        if (res.code===1){
          this.$message.success(res.msg)
          this.could_send = false
          this.btnText(60)
        }else{
          this.$message.error(res.msg)
        }
      })
    },

    btnText(t=60) {
      const timeinterval = setInterval(() => {
        --t;
        this.send_btn = '短信验证('+t+')'
        if (t <= 0) {
          this.could_send = true
          clearInterval(timeinterval);
        }
      },1000);
    }
  },
  created() {
    this.base = base+'/help/privacy.html'
  }
}
</script>

<style scoped>
.sendCode .el-button--primary.is-plain{
  color: #0941c1;
  background-color: #fff;
  border: 0.0625rem solid #144fd6;
}
.el-form-item{
  margin-bottom: 0;
}
.demo-ruleForm{
  box-sizing: border-box;
  padding: 0.625rem 5rem;
}
.blue{
  color: #1d5ae5;
  cursor: pointer;
}
.agree{
  text-align: center;
  color: #9b9b9b;
  font-size: 0.8125rem;
}
.login_title{
  font-size: 1.125rem;
  color: #000;
  font-weight: 700;
  text-align: center;
  margin: 1.25rem 0rem 2.5rem;
}
.sub .el-button--primary:focus, .el-button--primary:hover{
  background: #144fd6;
}
.sub .el-button{
  width: 80%;
  background: #0941c1;
  letter-spacing: 0.125rem;
}
</style>