<template>
  <div class="confirm_info">
    <div class="title_blue" style="margin-top: 6.25rem;margin-bottom: 5.625rem">
      确认信息
    </div>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="padding: 1.875rem 0rem">
        <span style="font-size: 1.875rem;font-weight: bold">院校信息</span>
<!--        <el-button style="float: right; padding: 3px 0;font-size: 30px" type="text">编辑</el-button>-->
      </div>
      <div class="item">
        <div>学校名</div>
        <div>{{this.$store.state.select_college_name}}</div>
      </div>
      <div class="item" v-if="this.$store.getters.getAccountName">
        <div>收款账户名</div>
        <div>{{this.$store.getters.getAccountName}}</div>
      </div>
      <div class="item">
        <div>收款账户号</div>
        <div>{{this.$store.getters.getAccountNum}}</div>
      </div>
      <div class="item" v-if="this.$store.getters.getCurrentAccount.bank_name">
        <div>收款银行</div>
        <div>{{this.$store.getters.getCurrentAccount.bank_name}}</div>
      </div>
      <div class="item" v-if="this.$store.getters.getCurrentAccount.swift_code">
        <div>SWIFT Code</div>
        <div>{{this.$store.getters.getCurrentAccount.swift_code}}</div>
      </div>
      <div class="item" v-if="this.$store.getters.getCurrentAccount.bsb_code">
        <div>BSB Number</div>
        <div>{{this.$store.getters.getCurrentAccount.bsb_code}}</div>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top: 1.5625rem">
      <div slot="header" class="clearfix" style="padding: 1.875rem 0rem">
        <span style="font-size: 1.875rem;font-weight: bold">金额信息</span>
<!--        <el-button style="float: right; padding: 3px 0;font-size: 30px" type="text">编辑</el-button>-->
      </div>
      <div class="item">
        <div>汇款金额</div>
        <div>{{this.$store.state.price}}{{this.$store.getters.getCurrencyName}}</div>
      </div>
      <div class="item">
        <div>平台服务费</div>
        <div>{{this.$store.state.serve_price}}人民币</div>
      </div>
      <div class="item">
        <div>足额手续费</div>
        <div>{{this.$store.state.full_price}}人民币</div>
      </div>
      <div class="item">
        <div>需支付金额</div>
        <div>{{this.$store.getters.getTotalPrice}}人民币</div>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top: 1.5625rem">
      <div slot="header" class="clearfix" style="padding: 1.875rem 0rem">
        <span style="font-size: 1.875rem;font-weight: bold">学生信息</span>
<!--        <el-button style="float: right; padding: 3px 0;font-size: 30px" type="text">编辑</el-button>-->
      </div>
      <div class="item">
        <div>学生姓名</div>
        <div>{{this.$store.state.two_name}}</div>
      </div>
      <div class="item">
        <div>姓氏拼音</div>
        <div>{{this.$store.state.two_surname}}</div>
      </div>
      <div class="item">
        <div>名字拼音</div>
        <div>{{this.$store.state.two_pinyin_name}}</div>
      </div>
      <div class="item">
        <div>身份证号</div>
        <div>{{this.$store.state.two_id_card}}</div>
      </div>
      <div class="item">
        <div>联系电话</div>
        <div>{{this.$store.state.two_phone}}</div>
      </div>
      <div class="item">
        <div>电子邮箱</div>
        <div>{{this.$store.state.two_email}}</div>
      </div>
      <div class="item">
        <div>学生证号</div>
        <div>{{this.$store.state.two_student_num}}</div>
      </div>
      <div class="item">
        <div>学制</div>
        <div>{{this.$store.state.two_educational_system}}</div>
      </div>
      <div class="item">
        <div>交易附言</div>
        <div>{{this.$store.state.two_postscript}}</div>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top: 1.5625rem">
      <div slot="header" class="clearfix" style="padding: 1.875rem 0rem">
        <span style="font-size: 1.875rem;font-weight: bold">付款人信息</span>
<!--        <el-button style="float: right; padding: 3px 0;font-size: 30px" type="text">编辑</el-button>-->
      </div>
      <div class="item">
        <div>与学生关系</div>
        <div>{{this.$store.state.two_pay_relation}}</div>
      </div>
      <div class="item">
        <div>付款人姓名</div>
        <div>{{this.$store.state.two_pay_name}}</div>
      </div>
      <div class="item">
        <div>付款人身份证号</div>
        <div>{{this.$store.state.two_pay_id_card}}</div>
      </div>
      <div class="item">
        <div>付款人联系电话</div>
        <div>{{this.$store.state.two_pay_phone}}</div>
      </div>
      <div class="item">
        <div>电子邮箱</div>
        <div>{{this.$store.state.two_pay_email}}</div>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top: 1.5625rem">
      <div slot="header" class="clearfix" style="padding: 1.875rem 0rem">
        <span style="font-size: 1.875rem;font-weight: bold">缴费证明</span>
<!--        <el-button style="float: right; padding: 3px 0;font-size: 30px" type="text">编辑</el-button>-->
      </div>
      <div class="item" v-for="(item,index) in this.$store.getters.getFileTwoItem" :key="index">
        <div>{{item}}</div>
        <div></div>
      </div>
    </el-card>
    <el-button type="primary" class="next_btn" style="margin-top: 7.5rem" @click="nextSept">我已确认信息无误，下一步</el-button>
    <el-button type="primary" class="next_btn" style="margin-top: 7.5rem" @click="backSept">信息有误，返回修改</el-button>
  </div>
</template>

<script>
import {req} from "@/apis/http";

export default {
  name: "ThreeTpl",
  data(){
    return {

    }
  },
  methods:{
    nextSept:function (){
      this.$store.dispatch('nextSeptAction',4)
    },
    backSept:function (){
      this.$store.dispatch('nextSeptAction',2)
    }
  },
  created() {
    window.scrollTo(0,0)
    //检查当前登录状态
    req('get','/api/token/check').then(res=>{
      if (res.code!==1){
        this.$router.push('/')
      }
    })
  }
}
</script>

<style scoped>

</style>