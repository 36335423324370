<template>
  <div>
    <HeaderTpl></HeaderTpl>
    <div style="position: relative">
      <div style="width: 100%;height: 5rem"></div>
      <el-empty v-if="list.length===0" description="暂无订单数据"></el-empty>
      <div v-else class="order_content">
        <el-card class="box-card" v-for="(i,index) in list" :key="index">
          <div slot="header" class="clearfix" @click="toPayPage(i)" style="cursor: pointer">
            <span>{{i.create_time}}</span>
            <el-button style="float: right; padding: 0.1875rem 0" type="text">
              <span v-if="i.pay_status==='已取消'" style="color: #F56C6C;font-weight:bold">{{i.pay_status}}</span>
              <span v-if="i.pay_status==='已支付'" style="color: #67C23A;font-weight:bold">{{i.pay_status}}</span>
              <span v-if="i.pay_status==='待支付'" style="font-weight: bold">{{i.pay_status}}</span>
            </el-button>
          </div>
          <div class="ul">
            <div class="li">
              <div class="left">汇款金额</div>
              <div class="right">{{i.remit_amount}}{{i.currency_code}}</div>
            </div>
          </div>
          <div class="ul">
            <div class="li">
              <div class="left">收款学校</div>
              <div class="right">{{i.college_name}}</div>
            </div>
          </div>
          <div class="ul">
            <div class="li">
              <div class="left">学生姓名</div>
              <div class="right">{{i.name_ch}}</div>
            </div>
          </div>
          <hr style="margin: 0.625rem 0;color:#EBEEF5" />
          <div class="ul">
            <div class="li">
              <div class="left">创建时间</div>
              <div class="right">{{i.create_time}}</div>
            </div>
          </div>
          <div class="ul">
            <div class="li">
              <div class="left">订单号</div>
              <div class="right">{{i.order_no}}</div>
            </div>
          </div>
        </el-card>
      </div>
      <FooterTpl></FooterTpl>
    </div>
  </div>
</template>

<script>
import HeaderTpl from "../components/HeaderTpl"
import FooterTpl from "@/components/FooterTpl.vue";
import {req} from "@/apis/http";
export default {
  name: "orderView",
  data(){
    return {
      list:[]
    }
  },
  methods:{
    toPayPage(e){
      let {order_no,stu_info_id,pay_status} = e
      if (pay_status==='待支付'){
        this.$store.dispatch('updateOrderNo',order_no)
        this.$store.dispatch('updateToPayType',2)
        this.$store.dispatch('nextSeptAction',4)
        this.$store.dispatch('StuInfoId',{stu_info_id:stu_info_id})
        this.$router.push('/pay')
      }
      if (pay_status==='已取消'){
        this.$message.warning('当前订单已取消')
      }
      if (pay_status==='已支付'){
        this.$message.warning('当前订单已完成')
      }
    }
  },
  components:{
    HeaderTpl,
    FooterTpl
  },
  created() {
    req('get','/api/user/orderList','').then(res=>{
      if (res.code===1){
        this.list = res.data
      }else if (res.code===-2){
        this.$router.push('/')
        this.$store.dispatch('updateLoginCardStatusAction',true)
      }else{
        this.$message.error(res.msg)
        this.$router.push('/')
      }
    })
  }
}
</script>

<style scoped>
  .order_content{
    width: 90rem;
    min-width: 90rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 5rem;
  }
  .order_content .box-card{
    width: 25rem;
    margin: 1.25rem;
  }
  .order_content .ul .li{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0;
  }
  .order_content .ul .li .left{
    width: 35%;
    font-weight: bold;
  }
  .order_content .ul .li .right{
    width: 65%;
    text-align: right;
  }
</style>