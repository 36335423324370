//操作数据
const mutations = {
    nextSeptAction:function (_,active){
        this.state.active = active
    },
    addCollegeListAction:function (_,list){
        this.state.college_list = list
        this.state.college_list.push([])
    },
    selectCollegeIdAction:function (_,id){
        this.state.college_select_id = id
    },
    selectCollegeNameAction:function (_,obj){
      this.state.select_college_name = obj.college_name;
      this.state.select_college_english_name=obj.english_name
    },
    addAccountListAction:function (_,list){
        this.state.account_list = list
    },
    setAccountId:function (_,obj){
        this.state.account_select_id = obj.id
        this.state.account_select_index = obj.index
    },
    updatePriceAction:function (_,price){
        let {account_list,account_select_index} = this.state
        let currency_rate = account_list[account_select_index]['currency_rate']
        let serve_price = (price*currency_rate*0.002).toFixed(2)
        if (serve_price>200){
            serve_price=200
        }
        if (serve_price<50){
            serve_price=50
        }
        this.state.price = price
        this.state.serve_price = serve_price
    },
    updateLoginCardStatusAction:function (_,status){
        this.state.show_login_card = status
    },
    //--------------------------------------------------------
    changeTwoName:function (content,value){
        this.state.two_name = value
    },
    changeTwoIdCard:function (content,value){
        this.state.two_id_card = value
    },
    changeTwoSurname:function (content,value){
        this.state.two_surname = value
    },
    changeTwoPhone:function (content,value){
        this.state.two_phone = value
    },
    changeTwoPinyinName:function (content,value){
        this.state.two_pinyin_name = value
    },
    changeTwoEmail:function (content,value){
        this.state.two_email = value
    },
    changeTwoStudentNum:function (content,value){
        this.state.two_student_num = value
    },
    changeTwoEducationalSystem:function (content,value){
        this.state.two_educational_system = value
    },
    changeTwoPostscript:function (content,value){
        this.state.two_postscript = value
    },
    changeTwoPayRelation:function (content,value){
        this.state.two_pay_relation = value
    },
    changeTwoPayName:function (content,value){
        this.state.two_pay_name = value
    },
    changeTwoPayIdCard:function (content,value){
        this.state.two_pay_id_card = value
    },
    changeTwoPayPhone:function (content,value){
        this.state.two_pay_phone = value
    },
    changeTwoPayEmail:function (content,value){
        this.state.two_pay_email = value
    },
    updateSourceAction:function (content,value){
        this.state.source_value = value
    },
    updateUserInfoAction:function (content,userinfo){
        this.state.token = userinfo.token
        this.state.username = userinfo.username
        this.state.mobile = userinfo.mobile
        this.state.id = userinfo.id
    },
    //添加文件1
    addFileListOne:function (content,file){
        let {file_list_one} = this.state
        file_list_one.push({file_path:file.file_path, file:file.file})
    },
    //移除文件1
    remoteFileOne:function (content,file){
      let {file_list_one} = this.state
      let arr = []
      file_list_one.map(i=>{
          if (i.file_path!==file.file_path){
              arr.push(i)
          }
      })
      this.state.file_list_one = arr
    },
    //添加文件2
    addFileListTwo:function (content,file){
        let {file_list_two} = this.state
        file_list_two.push({file_path:file.file_path, file:file.file})
    },
    //移除文件2
    remoteFileTwo:function (content,file){
        let {file_list_two} = this.state
        let arr = []
        file_list_two.map(i=>{
            if (i.file_path!==file.file_path){
                arr.push(i)
            }
        })
        this.state.file_list_two = arr
    },
    //添加文件3
    addFileListThree:function (content,file){
        let {file_list_three} = this.state
        file_list_three.push({file_path:file.file_path, file:file.file})
    },
    //移除文件3
    remoteFileThree:function (content,file){
        let {file_list_three} = this.state
        let arr = []
        file_list_three.map(i=>{
            if (i.file_path!==file.file_path){
                arr.push(i)
            }
        })
        this.state.file_list_three = arr
    },
    //添加文件4
    addFileListFour:function (content,file){
        let {file_list_four} = this.state
        file_list_four.push({file_path:file.file_path, file:file.file})
    },
    //移除文件4
    remoteFileFour:function (content,file){
        let {file_list_four} = this.state
        let arr = []
        file_list_four.map(i=>{
            if (i.file_path!==file.file_path){
                arr.push(i)
            }
        })
        this.state.file_list_four = arr
    },
    StuInfoId:function (content,obj){
        this.state.stu_info_id = obj.stu_info_id
    },
    //------------添加账户------------
    chanegAddSchoolName:function (content,value){
      this.state.add_school_name = value
    },
    changeAddAccountName:function (content,value){
        this.state.add_account_name = value
    },
    clearFileFour:function (content,value){
        console.log(value)
        this.state.file_list_four = ''
    },
    updateCollegeNameAction:function (content,college_name){
        this.state.select_college_name = college_name
    },





    initPayUseOptions:function (content,obj){
        this.state.pay_use_options = obj
    },
    updatePayUse:function (content,value){
        this.state.pay_use = value
    },
    initAction:function (content,obj){
        console.log(obj)
        //付款用途选项
        this.state.pay_use_options=[],
        //付款用途
        this.state.pay_use='',
        //登录卡片显示状态
        this.state.show_login_card=false,
        this.state.active=1,
        this.state.account_list=[],
        this.state.account_select_id='',
        this.state.account_select_index='',
        this.state.select_college_name='',
        this.state.select_college_english_name='',
        this.state.file_list_one=[],
        this.state.file_list_two=[],
        this.state.file_list_three=[],
        this.state.file_list_four=[],
    //汇款金额
        this.state.price='',
    //平台服务费
        this.state.serve_price=50,
    //足额到账费
        this.state.full_price=185,
        this.state.edu_years_options=[
            {
                id:1,
                name:'一年以上'
            },
            {
                id:2,
                name:'一年及一年以下'
            }
        ],
    //-----------学生信息-----------
    //学生姓名
        this.state.two_name='',
    //身份证号
        this.state.two_id_card='',
    //姓氏拼音
        this.state.two_surname='',
    //联系电话
        this.state.two_phone='',
    //名字拼音
        this.state.two_pinyin_name='',
    //电子邮箱
        this.state.two_email='',
    //-----------学籍信息----------
    //学生证号
        this.state.two_student_num='',
    //学制
        this.state.two_educational_system='',
    //附言
        this.state.two_postscript='',
    //---------付款人信息------------
    //与学生关系
        this.state.two_pay_relation='',
    //付款人姓名
        this.state.two_pay_name='',
    //付款人身份证号
        this.state.two_pay_id_card='',
    //付款人联系电话
        this.state.two_pay_phone='',
    //付款人电子邮箱
        this.state.two_pay_email='',
        this.state.source_value='',
        this.state.from_type = 1,
        this.state.account_id = ''
        this.state.stu_info_id = '',
        this.state.to_pay_type = 1
        this.state.order_no = ''
    },
    //更新fromtype字段
    updateFromType:function (content,type){
        this.state.from_type = type
    },
    updateAccountId:function (content,account_id){
        this.state.account_id=  account_id
    },
    //从订单页传到支付页的订单号
    updateOrderNo:function (content,order_no){
        this.state.order_no = order_no
    },
    //更新to_pay_type的值
    updateToPayType:function (content,to_pay_type){
        this.state.to_pay_type = to_pay_type
    }
}

export default mutations