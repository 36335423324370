//响应组件中的事件
const actions = {
    //更新当前步骤下标
    nextSeptAction:function (content,active){
        content.commit("nextSeptAction",active)
    },
    //更新院校选项列表
    addCollegeListAction:function (content,list){
        content.commit('addCollegeListAction',list)
    },
    //更新选择院校ID
    selectCollegeIdAction:function (content,id){
        content.commit('selectCollegeIdAction',id)
    },
    selectCollegeNameAction:function (content,obj){
        content.commit('selectCollegeNameAction',obj)
    },
    //院校收款账户列表
    addAccountListAction:function (content,list){
        content.commit('addAccountListAction',list)
    },
    //设置当前选择付款账户ID
    setAccountId:function (content,obj){
        content.commit('setAccountId',obj)
    },
    //更新汇款金额
    updatePriceAction:function (content,price){
        content.commit('updatePriceAction',price)
    },
    //更新登录卡片显示状态
    updateLoginCardStatusAction:function (content,status){
        content.commit('updateLoginCardStatusAction',status)
    },
    //-------------------------------------学生、付款人信息-----------------------------
    changeTwoName:function (content,value){
        content.commit('changeTwoName',value)
    },
    changeTwoIdCard:function (content,value){
        content.commit('changeTwoIdCard',value)
    },
    changeTwoSurname:function (content,value){
        content.commit('changeTwoSurname',value)
    },
    changeTwoPhone:function (content,value){
        content.commit('changeTwoPhone',value)
    },
    changeTwoPinyinName:function (content,value){
        content.commit('changeTwoPinyinName',value)
    },
    changeTwoEmail:function (content,value){
        content.commit('changeTwoEmail',value)
    },
    changeTwoStudentNum:function (content,value){
        content.commit('changeTwoStudentNum',value)
    },
    changeTwoEducationalSystem:function (content,value){
        content.commit('changeTwoEducationalSystem',value)
    },
    changeTwoPostscript:function (content,value){
        content.commit('changeTwoPostscript',value)
    },
    changeTwoPayRelation:function (content,value){
        content.commit('changeTwoPayRelation',value)
    },
    changeTwoPayName:function (content,value){
        content.commit('changeTwoPayName',value)
    },
    changeTwoPayIdCard:function (content,value){
        content.commit('changeTwoPayIdCard',value)
    },
    changeTwoPayPhone:function (content,value){
        content.commit('changeTwoPayPhone',value)
    },
    changeTwoPayEmail:function (content,value){
        content.commit('changeTwoPayEmail',value)
    },
    updateSourceAction:function (content,value){
        content.commit('updateSourceAction',value)
    },
    updateUserInfoAction:function (content,userinfo){
        content.commit('updateUserInfoAction',userinfo)
    },

    //添加账户
    chanegAddSchoolName:function (content,value){
        content.commit('chanegAddSchoolName',value)
    },
    changeAddAccountName:function (content,value){
      content.commit('changeAddAccountName',value)
    },
    clearFileFour:function (content,value){
        content.commit('clearFileFour',value)
    },
    StuInfoId:function (content,obj){
        content.commit('StuInfoId',obj)
    },

    //---------------文件上传------------------
    addFileListOne:function (content,file){
        content.commit('addFileListOne',file)
    },
    addFileListTwo:function (content,file){
        content.commit('addFileListTwo',file)
    },
    addFileListThree:function (content,file){
        content.commit('addFileListThree',file)
    },
    addFileListFour:function (content,file){
        content.commit('addFileListFour',file)
    },
    initPayUseOptions:function (content,obj){
        content.commit('initPayUseOptions',obj)
    },
    updatePayUse:function (content,value){
        content.commit('updatePayUse',value)
    },
    remoteFileOne:function (content,file){
        content.commit('remoteFileOne',file)
    },
    remoteFileTwo:function (content,file){
        content.commit('remoteFileTwo',file)
    },
    remoteFileThree:function (content,file){
        content.commit('remoteFileThree',file)
    },
    remoteFileFour:function (content,file){
        content.commit('remoteFileFour',file)
    },
    initAction:function (content,obj){
        content.commit('initAction',obj)
    },
    updateFromType:function (content,type){
        content.commit('updateFromType',type)
    },
    updateAccountId:function (content,account_id){
        content.commit('updateAccountId',account_id)
    },
    updateCollegeNameAction:function (content,college_name){
        content.commit('updateCollegeNameAction',college_name)
    },
    updateOrderNo:function (content,order_no){
        content.commit('updateOrderNo',order_no)
    },
    updateToPayType:function (content,to_pay_type){
        content.commit('updateToPayType',to_pay_type)
    }
}

export default actions