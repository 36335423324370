import Vue from 'vue'
import VueRouter from "vue-router";
import indexView from "@/views/indexView";
import PayView from "@/views/PayView"
import addView from "@/views/addView";
import orderView from "@/views/orderView.vue";

Vue.use(VueRouter)

const router = [
    {
        path:'/',
        component:indexView,
        meta:{
            title:'留学e汇'
        }
    },
    {
        path:'/pay',
        component: PayView,
        meta:{
            title:'留学e汇'
        }
    },
    {
        path:'/add',
        component: addView,
        meta:{
            title:'留学e汇'
        }
    },
    {
        path:'/order',
        component: orderView,
        meta:{
            title:'留学e汇'
        }
    }
]

export default new VueRouter({
    routes:router,
    mode:'history'
})