import Vue from "vue"
import Vuex from "vuex"
import actions from "@/store/actions";
import mutations from "@/store/mutations";
import getters from "@/store/getters";

Vue.use(Vuex)

//存储数据
const state = {
    //付款用途选项
    pay_use_options:[],
    //付款用途
    pay_use:'',
    //登录卡片显示状态
    show_login_card:false,
    active:1,
    account_list:[],
    account_select_id:'',
    account_select_index:'',
    select_college_name:'',
    select_college_english_name:'',
    file_list_one:[],
    file_list_two:[],
    file_list_three:[],
    file_list_four:[],
    //汇款金额
    price:'',
    //平台服务费
    serve_price:50,
    //足额到账费
    full_price:185,
    edu_years_options:[
        {
            id:1,
            name:'一年以上'
        },
        {
            id:2,
            name:'一年及一年以下'
        }
    ],
    //-----------学生信息-----------
    //学生姓名
    two_name:'',
    //身份证号
    two_id_card:'',
    //姓氏拼音
    two_surname:'',
    //联系电话
    two_phone:'',
    //名字拼音
    two_pinyin_name:'',
    //电子邮箱
    two_email:'',
    //-----------学籍信息----------
    //学生证号
    two_student_num:'',
    //学制
    two_educational_system:'',
    //附言
    two_postscript:'',
    //---------付款人信息------------
    //与学生关系
    two_pay_relation:'',
    //付款人姓名
    two_pay_name:'',
    //付款人身份证号
    two_pay_id_card:'',
    //付款人联系电话
    two_pay_phone:'',
    //付款人电子邮箱
    two_pay_email:'',
    source_value:'',
    add_school_name:'',
    add_account_name:'',
    stu_info_id:'',
    //来源类型：1首页搜索院校 2自行新增院校
    from_type:1,
    account_id:'',
    //1正常走到支付页，2从订单页走到支付页
    to_pay_type:1,
    //从订单页传到支付页的订单号
    order_no:'',

    //------------用户登录信息----------------
    token:'',
    username:'',
    mobile:'',
    id:''


}

export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters
})