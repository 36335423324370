<template>
  <div style="position: relative">
    <HeaderTpl></HeaderTpl>
    <div style="width: 100%;height: 5rem"></div>
    <div class="add_main">
      <div class="title_blue" style="margin-top: 6.25rem;margin-bottom: 5.625rem">
        添加院校账户信息
      </div>
      <div class="item long">
        <div class="title">学校名称</div>
        <el-input placeholder="中文或英文均可" :value="this.$store.state.select_college_name" @input="changeSchoolName"></el-input>
      </div>
      <div class="item long">
        <div class="title">选择货币类型</div>
        <el-select placeholder="请选择货币类型" v-model="currency_value" @change="changeAccountId">
          <el-option
              v-for="ii in currency"
              :key="ii.id"
              :label="ii.name"
              :value="ii.id">
            {{ii.name}}
          </el-option>
        </el-select>
      </div>
      <div class="item long">
        <div class="title">收款账户</div>
        <el-input placeholder="填写学校收款账户" :value="this.$store.state.add_account_name" @input="changeAccountName"></el-input>
      </div>

      <div class="item long">
        <div class="title">(请提交含院校账户信息的文件或图片最多4个文件) <span class="blue size25">文件要求</span></div>
        <div slot="tip" v-if="this.$store.state.file_list_four.length>0" class="img_box">
          <div v-for="(img,index) in this.$store.state.file_list_four" style="position: relative" :key="index">
            <i class="el-icon-delete delete_icon" @click="remotePicFour(img.file_path)"></i>
            <img v-if="img.file.response.data.file_ext!=='application/pdf'" :src="img.file_path" class="avatar" />
            <img v-else src="../assets/pdf.png" class="avatar" />
          </div>
        </div>
        <el-upload
            :action=uploadPath
            :auto-upload="true"
            :on-success="handleSuccessFour"
            :before-remove="beforeRemoveFour"
            :headers=headers
            :show-file-list="false"
        >
          <div v-if="this.$store.state.file_list_four.length<4" style="width: 6.25rem;height: 6.25rem;border: 0.0625rem solid #cccccc;background: #eeeeee;text-align: center;display: flex;flex-direction: column;align-items: center;justify-content: space-around;box-sizing: border-box;padding: 1.25rem">
            <i slot="default" class="el-icon-plus" style="color: #959595;font-size: 1.875rem"></i>
            <div style="font-size: 1rem;color: #959595">上传</div>
          </div>
        </el-upload>
      </div>
      <div class="item long">
        <div class="title">请选择信息来源</div>
        <el-select :value="this.$store.state.source_value" placeholder="请选择" @change="sourceChange">
          <el-option
              v-for="item in source"
              :key="item.id"
              :value="item.name"
          ></el-option>
        </el-select>
      </div>
      <el-button @click="submitAdd">提交</el-button>
<!--      <div class="blue_center">有问题？联系专属客服添加</div>-->
      <div class="infomation">
        <div class="title">文件要求</div>
        <div class="s_title">1、图片需包含以下信息，添加图片账号信息后，可以继续进行缴费：</div>
        <div class="one_item">
          <div class="li">
            <div class="l">①学校信息</div>
            <div class="r">学校名称/官网地址/邮箱logo等</div>
          </div>
          <div class="li">
            <div class="l">②收款账户名</div>
            <div class="r">Account Name</div>
          </div>
          <div class="li">
            <div class="l">③收款账户号</div>
            <div class="r">IBAN</div>
          </div>
          <div class="li">
            <div class="l">④Sort Code</div>
            <div class="r">6位数字清算号</div>
          </div>
          <div class="li">
            <div class="l">⑤SWIFT Code</div>
            <div class="r">8位或11位银行识别码</div>
          </div>
        </div>
        <div class="s_title">2、部分国家实施国内银行代码，参考如下：</div>
        <div class="two_item">
          <div class="li">
            <div class="l" style="font-weight: bold">国家</div>
            <div class="r" style="font-weight: bold;color: #000000">银行代码</div>
          </div>
          <div class="li">
            <div class="l">美国</div>
            <div class="r">Routing Number</div>
          </div>
          <div class="li">
            <div class="l">加拿大</div>
            <div class="r">Routing Number</div>
          </div>
          <div class="li">
            <div class="l">英国</div>
            <div class="r">Sort Codes</div>
          </div>
          <div class="li">
            <div class="l">澳大利亚</div>
            <div class="r">BSB number</div>
          </div>
          <div class="li">
            <div class="l">新西兰</div>
            <div class="r">BSB number</div>
          </div>
          <div class="li">
            <div class="l">印度</div>
            <div class="r">IFSC Code</div>
          </div>
        </div>
        <div class="s_title">3、通过下列来源或咨询校方获得</div>
        <div class="imgs">
          <img src="../assets/1.jpg" alt="">
          <img src="../assets/2.jpg" alt="">
          <img src="../assets/3.jpg" alt="">
        </div>
        <div style="width: 100%;height: 5.625rem"></div>
      </div>
    </div>
    <FooterTpl></FooterTpl>
  </div>
</template>

<script>
import HeaderTpl from "@/components/HeaderTpl";
import FooterTpl from "@/components/FooterTpl.vue";
import {req, uploadPath} from "@/apis/http";
export default {
  name: "addView",
  methods:{
    remotePicFour(filepath){
      this.$store.dispatch('remoteFileFour', {file_path:filepath})
    },
    changeAccountId(value){
      // let {currency,account_name} = this
      this.$store.dispatch('updateAccountId',value)
    },
    submitAdd(){
      let {state} = this.$store
      if (state.select_college_name===''){
        this.$message.error('学校名称不能为空')
        return false
      }
      if (state.add_account_name===''){
        this.$message.error('收款账户不能为空')
        return false
      }
      if (state.source_value===''){
        this.$message.error('信息来源不能为空')
        return false
      }
      let source_image = [];
      if (state.file_list_four.length<1){
        this.$message.error('请提交含院校账户信息的文件或图片')
        return false
      }
      if (state.account_id===''){
        this.$message.error('请选择货币类型')
        return false
      }
      state.file_list_four.map(iii=>{
        source_image.push(iii.file_path)
      })
      source_image = JSON.stringify(source_image)
      req('post','/api/user/addCollegeAccount',{college_name:state.select_college_name,account_num:state.add_account_name,source_type:state.source_value,source_image:source_image,currency_id:state.account_id}).then(res=>{
        if (res.code!==1){
          this.$message.error(res.msg)
          return false
        }else{
          // this.$store.dispatch('chanegAddSchoolName','')
          // this.$store.dispatch('changeAddAccountName','')
          // this.$store.dispatch('clearFileFour','')
          this.$message.success(res.msg)
          this.$store.dispatch('updateFromType',2)
          this.$store.dispatch('updateAccountId',res.data.account_id)
          this.$store.dispatch('addAccountListAction',res.data)
          this.$router.push('/pay')
          // setTimeout(location.reload(),1000)
        }
      })
    },
    changeSchoolName(value){
      this.$store.dispatch('updateCollegeNameAction',value)
    },
    changeAccountName(value){
      this.$store.dispatch('changeAddAccountName',value)
    },
    handleSuccessFour:function (res,file){
      if (res.code!==1){
        this.$store.dispatch('updateLoginCardStatusAction',true)
      }else{
        this.$store.dispatch('addFileListFour',{file_path:res.data.file_path,file:file})
      }
    },
    beforeRemoveFour:function (file, fileList){
      console.log(fileList)
      console.log(file)
      this.$store.dispatch('remoteFileFour',{file_path:file.response.data.file_path})
    },
    exceed_one:function (files,fileList){
      console.log(files)
      console.log(fileList)
      this.$message.error('最多上传4个文件')
      return false
    },
    sourceChange(value){
      this.$store.dispatch('updateSourceAction',value)
    }
  },
  data(){
    return {
      currency_value:'',
      account_name:'',
      four_limit:4,
      source:[],
      currency:[],
      uploadPath:uploadPath,
      headers:{token: localStorage.getItem('token')}
    }
  },
  components:{
    HeaderTpl,
    FooterTpl
  },
  created() {
    //检查当前登录状态
    req('get','/api/token/check').then(res=>{
      if (res.code!==1){
        this.$message.error(res.msg)
        this.$store.dispatch('updateLoginCardStatusAction',true)
        this.$router.push('/')
      }
    })
    this.headers = {token: localStorage.getItem('token')}
    req('get','/api/common/collegeSource').then(res=>{
      this.source=res.data
    })
    req('get','/api/common/currency').then(res=>{
      this.currency = res.data
    })
  }
}
</script>

<style scoped>
.img_box{
  display: flex;
  flex-direction: row;
}
.img_box .delete_icon{
  position: absolute;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 1.25rem;
  left: 4.0625rem;
  top: 4.0625rem;
}
.img_box img{
  width: 9.375rem;
  height: 9.375rem;
  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
}
.imgs{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.imgs img{
  width: 13.125rem;
}
.s_title{
  font-size: 1.375rem;
  margin: 2.5rem 0rem;
}
.one_item .li{
  width: 43.75rem;
  margin-left: 3.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.one_item .li .l{
  width: 12.5rem;
  font-size: 1.5rem;
  margin: 0.625rem 0;
}
.one_item .li .r{
  width: 25rem;
  font-size: 1.5rem;
  margin: 0.625rem 0;
  color: #535353;
}
.two_item .li{
  width: 43.75rem;
  margin-left: 3.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.two_item .li .l{
  width: 12.5rem;
  font-size: 1.5rem;
  margin: 0.625rem 0;
  color: #3d3d42;
}
.two_item .li .r{
  width: 25rem;
  font-size: 1.5rem;
  margin: 0.625rem 0;
  color: #3d3d42;
}
.el-button{
  width: 100%;
  height: 7.8125rem;
  line-height: 7.8125rem;
  padding: 0;
  background: #008cd6;
  color: #FFFFFF;
  font-size: 2.5rem;
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  border-radius: 1.25rem;
}
  @import "../css/add.css";
</style>