import { render, staticRenderFns } from "./FourTpl.vue?vue&type=template&id=6164ed38&scoped=true&"
import script from "./FourTpl.vue?vue&type=script&lang=js&"
export * from "./FourTpl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6164ed38",
  null
  
)

export default component.exports