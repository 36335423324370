import Vue from 'vue'
import App from './App.vue'
import ElementUI, {Message} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "@/router/index"
import store from '@/store/index'
import './amfe-flexible'

Vue.config.productionTip = false
Vue.use(ElementUI)

//----------------开始自定义message高度----------------------
Vue.prototype.$message = function (options){
  options.offset = 120
  return Message(options)
}
Vue.prototype.$message.error = function (msg){
  return Message.error({
    message:msg,
    offset:120
  })
}
Vue.prototype.$message.success = function (msg){
  return Message.success({
    message:msg,
    offset:120
  })
}
Vue.prototype.$message.warning = function (msg){
  return Message.warning({
    message:msg,
    offset:120
  })
}
//----------------结束自定义message高度----------------------
router.beforeEach((to,from,next)=>{
  if (to.meta.title){
    document.title = to.meta.title
  }
  next()
})

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
