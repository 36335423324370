const upFixed =  (num, fix) => {
    // num为原数字，fix是保留的小数位数
    let result = '0'
    if (Number(num) && fix > 0) { // 简单的做个判断
        fix = +fix || 2
        num = num + ''
        if (/e/.test(num)) { // 如果是包含e字符的数字直接返回
            result = num
        } else if (!/\./.test(num)) { // 如果没有小数点
            result = num + `.${Array(fix + 1).join('0')}`
        } else { // 如果有小数点
            num = num + `${Array(fix + 1).join('0')}`
            let reg = new RegExp(`-?\\d*\\.\\d{0,${fix}}`)
            let floorStr = reg.exec(num)[0]
            if (+floorStr >= +num) {
                result = floorStr
            } else {
                let floorNumber = +floorStr + +`0.${Array(fix).join('0')}1`
                let point = /\./.test(floorNumber + '') ? '' : '.'
                let floorStr2 = floorNumber + point + `${Array(fix + 1).join('0')}`
                result = reg.exec(floorStr2)[0]
            }
        }
    }
    return result
}

//将时间戳转换为日期格式
const timestampToTime = (timestamp) => {
    // 时间戳为10位需*1000，时间戳为13位不需乘1000
    var date = new Date(timestamp * 1000);
    var Y = date.getFullYear() + "-";
    var M =
        (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
    var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
    var s = (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());
    return Y + M + D + h + m + s;
}

//秒转分钟秒数
const timeToStr = (seconds) => {
    // 转换为式分秒
    let h = parseInt(seconds / 60 / 60 % 24)
    h = h < 10 ? '0' + h : h
    let m = parseInt(seconds / 60 % 60)
    m = m < 10 ? '0' + m : m
    let s = parseInt(seconds % 60)
    s = s < 10 ? '0' + s : s
    // 作为返回值返回
    return h + ':' + m + ':' + s
}

export {
    upFixed,timestampToTime,timeToStr
}