<template>
  <div class="fill_in_info">
    <div class="title_blue" style="margin-top: 6.25rem;margin-bottom: 5.625rem">
      学生信息
    </div>
    <div class="base_info">
      <div class="item">
        <div class="title">学生姓名</div>
        <el-input placeholder="请输入学生中文姓名" :value="this.$store.state.two_name" @input="changeTwoName"></el-input>
      </div>
      <div class="item">
        <div class="title">身份证号</div>
        <el-input placeholder="中国身份证号码" :value="this.$store.state.two_id_card" @input="changeTwoIdCard"></el-input>
      </div>
      <div class="item">
        <div class="title">姓氏拼音</div>
        <el-input placeholder="与录取通知书一致" :value="this.$store.state.two_surname" @input="changeTwoSurname"></el-input>
      </div>
      <div class="item">
        <div class="title">联系电话</div>
        <el-input placeholder="用于接收通知的大陆手机号" :value="this.$store.state.two_phone" @input="changeTwoPhone"></el-input>
      </div>
      <div class="item">
        <div class="title">名字拼音</div>
        <el-input placeholder="与录取通知书一致" :value="this.$store.state.two_pinyin_name" @input="changeTwoPinyinName"></el-input>
      </div>
      <div class="item">
        <div class="title">电子邮箱</div>
        <el-input placeholder="用于接收通知“汇款回执”的邮箱" :value="this.$store.state.two_email" @input="changeTwoEmail"></el-input>
      </div>
    </div>
    <div class="title_blue" style="margin-top: 6.25rem">学籍信息</div>
    <div class="base_info">
      <div class="item">
        <div class="title">学生证号</div>
        <el-input placeholder="输入Student ID，若没有填写“无”" :value="this.$store.state.two_student_num" @input="changeTwoStudentNum"></el-input>
      </div>
      <div class="item">
        <div class="title">学制</div>
        <el-select placeholder="请选择" :value="this.$store.state.two_educational_system" @change="changeTwoEducationalSystem">
          <el-option
              v-for="ii in this.$store.state.edu_years_options"
              :key="ii.id"
              :value="ii.name">
          </el-option>
        </el-select>
      </div>
      <div class="item fy">
        <div class="title">附言</div>
        <el-input placeholder="附言很重要，请按照学校要求填写" :value="this.$store.state.two_postscript" @input="changeTwoPostscript"></el-input>
      </div>
    </div>
    <div class="title_blue" style="margin-top: 6.25rem">付款人信息</div>
    <div class="base_info">
      <div class="item long">
        <div class="title">与学生关系</div>
        <el-select placeholder="选择付款人与学生的关系" :value="this.$store.state.two_pay_relation" @change="changeTwoPayRelation">
          <el-option
              v-for="item in options"
              :key="item.id"
              :value="item.name">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="title">姓名</div>
        <el-input placeholder="输入付款人中文姓名" :value="this.$store.state.two_pay_name" @input="changeTwoPayName"></el-input>
      </div>
      <div class="item">
        <div class="title">身份证号</div>
        <el-input placeholder="输入付款人身份证号" :value="this.$store.state.two_pay_id_card" @input="changeTwoPayIdCard"></el-input>
      </div>
      <div class="item">
        <div class="title">联系电话</div>
        <el-input placeholder="输入付款人联系电话" :value="this.$store.state.two_pay_phone" @input="changeTwoPayPhone"></el-input>
      </div>
      <div class="item">
        <div class="title">电子邮箱</div>
        <el-input placeholder="用于接收通知“汇款回执”的邮箱" :value="this.$store.state.two_pay_email" @input="changeTwoPayEmail"></el-input>
      </div>
    </div>
    <div class="title_blue" style="margin-top: 6.25rem">提供缴费证明</div>
    <div class="small_title_2">支持上传PDF、图片、单个文件需要小于10M</div>
    <div class="small_title">上传录取通知书/在读证明（最多4个文件）</div>
    <div class="small_title" style="font-size: 1.375rem">可上传offer、录取邮件截图、非大一学生可上传学生证或其他证明</div>

    <div slot="tip" v-if="this.$store.state.file_list_one.length>0" class="img_box">
      <div v-for="(img,index) in this.$store.state.file_list_one" style="position: relative" :key="index">
        <i class="el-icon-delete delete_icon" @click="remotePicOne(img.file_path)"></i>
        <img v-if="img.file.response.data.file_ext!=='application/pdf'" :src="img.file_path" class="avatar" />
        <img v-else src="../assets/pdf.png" class="avatar" />
      </div>
    </div>
    <el-upload
        :action=uploadPath
        list-type="picture"
        :auto-upload="true"
        :on-success="handleSuccessOne"
        :before-remove="beforeRemoveOne"
        :headers="headers"
        :show-file-list="false"
    >
      <div v-if="this.$store.state.file_list_one.length<4" style="width: 6.25rem;height: 6.25rem;border: 0.0625rem solid #cccccc;background: #eeeeee;text-align: center;display: flex;flex-direction: column;align-items: center;justify-content: space-around;box-sizing: border-box;padding: 1.25rem">
        <i slot="default" class="el-icon-plus" style="color: #959595;font-size: 1.875rem"></i>
        <div style="font-size: 1rem;color: #959595">上传</div>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

    <el-divider></el-divider>
    <div class="small_title">上传缴费通知单（最多4个文件）</div>
    <div slot="tip" v-if="this.$store.state.file_list_two.length>0" class="img_box">
      <div v-for="(img,index) in this.$store.state.file_list_two" style="position: relative" :key="index">
        <i class="el-icon-delete delete_icon" @click="remotePicTwo(img.file_path)"></i>
        <img v-if="img.file.response.data.file_ext!=='application/pdf'" :src="img.file_path" class="avatar" />
        <img v-else src="../assets/pdf.png" class="avatar" />
      </div>
    </div>
    <el-upload
        :action=uploadPath
        list-type="picture"
        :auto-upload="true"
        :on-success="handleSuccessTwo"
        :before-remove="beforeRemoveTwo"
        :headers="headers"
        :show-file-list="false"
    >
      <div v-if="this.$store.state.file_list_two.length<4" style="width: 6.25rem;height: 6.25rem;border: 0.0625rem solid #cccccc;background: #eeeeee;text-align: center;display: flex;flex-direction: column;align-items: center;justify-content: space-around;box-sizing: border-box;padding: 1.25rem">
        <i slot="default" class="el-icon-plus" style="color: #959595;font-size: 1.875rem"></i>
        <div style="font-size: 1rem;color: #959595">上传</div>
      </div>
    </el-upload>
    <div class="small_title">上传护照资料页+护照签证页（选填，最多4个文件）</div>
    <div slot="tip" v-if="this.$store.state.file_list_three.length>0" class="img_box">
      <div v-for="(img,index) in this.$store.state.file_list_three" style="position: relative" :key="index">
        <i class="el-icon-delete delete_icon" @click="remotePicThree(img.file_path)"></i>
        <img v-if="img.file.response.data.file_ext!=='application/pdf'" :src="img.file_path" class="avatar" />
        <img v-else src="../assets/pdf.png" class="avatar" />
      </div>
    </div>
    <el-upload
        :action=uploadPath
        list-type="picture"
        :auto-upload="true"
        :on-success="handleSuccessThree"
        :before-remove="beforeRemoveThree"
        :headers="headers"
        :show-file-list="false"
    >
      <div v-if="this.$store.state.file_list_three.length<4" style="width: 6.25rem;height: 6.25rem;border: 0.0625rem solid #cccccc;background: #eeeeee;text-align: center;display: flex;flex-direction: column;align-items: center;justify-content: space-around;box-sizing: border-box;padding: 1.25rem">
        <i slot="default" class="el-icon-plus" style="color: #959595;font-size: 1.875rem"></i>
        <div style="font-size: 1rem;color: #959595">上传</div>
      </div>
    </el-upload>
    <el-button type="primary" class="next_btn" style="margin-top: 7.5rem" @click="nextSept">下一步</el-button>
  </div>
</template>

<script>
import {req,uploadPath} from "@/apis/http";

export default {
  name: "TwoTpl",
  data(){
    return {
      four_limit:4,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      uploadPath:uploadPath,
      options:[],
      headers:{token:this.$store.state.token},
      file_list_one: this.$store.state.file_list_one
    }
  },
  methods:{
    remotePicOne(filepath){
      this.$store.dispatch('remoteFileOne', {file_path:filepath})
    },
    remotePicTwo(filepath){
      this.$store.dispatch('remoteFileTwo', {file_path:filepath})
    },
    remotePicThree(filepath){
      this.$store.dispatch('remoteFileThree', {file_path:filepath})
    },
    beforeRemoveOne:function (file, fileList){
      console.log(fileList)
      console.log(file)
      this.$store.dispatch('remoteFileOne',{file_path:file.response.data.file_path})
    },
    beforeRemoveTwo:function (file, fileList){
      console.log(fileList)
      console.log(file)
      this.$store.dispatch('remoteFileTwo',{file_path:file.response.data.file_path})
    },
    beforeRemoveThree:function (file, fileList){
      console.log(fileList)
      console.log(file)
      this.$store.dispatch('remoteFileThree',{file_path:file.response.data.file_path})
    },
    exceed_one:function (files,fileList){
      console.log(files)
      console.log(fileList)
      this.$message.error('最多上传4个文件')
      return false
    },
    handleSuccessOne:function (res,file){
      if (res.code===1){
        this.$store.dispatch('addFileListOne',{file_path:res.data.file_path,file:file})
      }else if (res.code===-2){
        this.$store.dispatch('updateLoginCardStatusAction',true)
      }else{
        this.$message.error(res.msg)
      }
    },
    handleSuccessTwo:function (res,file){
      if (res.code===1){
        this.$store.dispatch('addFileListTwo',{file_path:res.data.file_path,file:file})
      }else if(res.code===-2){
        this.$store.dispatch('updateLoginCardStatusAction',true)
      }else{
        this.$message.error(res.msg)
      }
    },
    handleSuccessThree:function (res,file){
      if (res.code===1){
        this.$store.dispatch('addFileListThree',{file_path:res.data.file_path,file:file})
      }else if (res.code===-2){
        this.$store.dispatch('updateLoginCardStatusAction',true)
        return false
      }else{
        this.$message.error(res.msg)
        return false
      }
    },
    //点击移除图片
    handleRemove(file) {
      console.log(file);
    },
    //点击预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    nextSept:function (){
      let {state,getters} = this.$store
      if (state.two_name===''){
        this.$message.error('学生姓名不能为空')
        return false
      }
      if (state.two_id_card===''){
        this.$message.error('身份证号不能为空')
        return false
      }
      if (state.two_surname===''){
        this.$message.error('姓氏拼音不能为空')
        return false
      }
      if (state.two_phone===''){
        this.$message.error('联系电话不能为空')
        return false
      }
      if (state.two_pinyin_name===''){
        this.$message.error('名字拼音不能为空')
        return false
      }
      if (state.two_email===''){
        this.$message.error('电子邮箱不能为空')
        return false
      }
      if (state.two_student_num===''){
        this.$message.error('学生证号不能为空')
        return false
      }
      if (state.two_educational_system===''){
        this.$message.error('学制不能为空')
        return false
      }
      if (state.two_postscript===''){
        this.$message.error('附言不能为空')
        return false
      }
      if (state.two_pay_relation===''){
        this.$message.error('与学生关系选项不能为空')
        return false
      }
      if (state.two_pay_name===''){
        this.$message.error('付款人姓名不能为空')
        return false
      }
      if (state.two_pay_id_card===''){
        this.$message.error('付款人身份证号不能为空')
        return false
      }
      if (state.two_pay_phone===''){
        this.$message.error('付款人联系电话不能为空')
        return false
      }
      if (state.two_pay_email===''){
        this.$message.error('付款人电子邮箱不能为空')
        return false
      }
      if (state.file_list_one.length<1){
        this.$message.error('请上传录取通知书/在读证明')
        return false
      }
      if (state.file_list_two.length<1){
        this.$message.error('请上传缴费通知单')
        return false
      }
      let {stu_info_id} = this.$store.state
      req('post','/api/user/saveInfo',{
        name_ch:state.two_name,
        id_card:state.two_id_card,
        name_last:state.two_surname,
        name_first:state.two_pinyin_name,
        mobile:state.two_phone,
        email:state.two_email,
        student_id:state.two_student_num,
        edu_year:state.two_educational_system,
        remark:state.two_postscript,
        payer_relation:state.two_pay_relation,
        payer_name_ch:state.two_pay_name,
        payer_id_card:state.two_pay_id_card,
        payer_mobile:state.two_pay_phone,
        payer_email:state.two_pay_email,
        admit_letter:getters.getFileListOneJson,
        payment_notice:getters.getFileListTwoJson,
        passport_info:getters.getFileListThreeJson,
        stu_info_id:stu_info_id
      }).then(res=>{
        if (res.code===1){
          this.$store.dispatch('StuInfoId', {stu_info_id:res.data.stu_info_id})
          this.$message.success(res.msg)
          this.$store.dispatch('nextSeptAction',3)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    //修改学生姓名
    changeTwoName(value){
      this.$store.dispatch('changeTwoName',value)
    },
    //修改学生身份证号
    changeTwoIdCard(value){
      this.$store.dispatch('changeTwoIdCard',value)
    },
    //修改学生姓氏拼音
    changeTwoSurname(value){
      this.$store.dispatch('changeTwoSurname',value)
    },
    //修改学生联系电话
    changeTwoPhone(value){
      this.$store.dispatch('changeTwoPhone',value)
    },
    //修改学生名字拼音
    changeTwoPinyinName(value){
      this.$store.dispatch('changeTwoPinyinName',value)
    },
    //修改学生电子邮箱
    changeTwoEmail(value){
      this.$store.dispatch('changeTwoEmail',value)
    },
    //修改学生证号
    changeTwoStudentNum(value){
      this.$store.dispatch('changeTwoStudentNum',value)
    },
    //修改学制
    changeTwoEducationalSystem(value){
      this.$store.dispatch('changeTwoEducationalSystem',value)
    },
    //修改附言
    changeTwoPostscript(value){
      this.$store.dispatch('changeTwoPostscript',value)
    },
    //修改与学生关系【付款人】
    changeTwoPayRelation(value){
      this.$store.dispatch('changeTwoPayRelation',value)
    },
    //修改姓名【付款人】
    changeTwoPayName(value){
      this.$store.dispatch('changeTwoPayName',value)
    },
    //修改身份证号【付款人】
    changeTwoPayIdCard(value){
      this.$store.dispatch('changeTwoPayIdCard',value)
    },
    // 修改联系电话【付款人】
    changeTwoPayPhone(value){
      this.$store.dispatch('changeTwoPayPhone',value)
    },
    // 修改电子邮箱【付款人】
    changeTwoPayEmail(value){
      this.$store.dispatch('changeTwoPayEmail',value)
    }
  },
  created() {
    window.scrollTo(0,0)
    //检查当前登录状态
    req('get','/api/token/check').then(res=>{
      if (res.code!==1){
        this.$router.push('/')
      }
    })
    req('get','/api/common/studentRelation').then(res=>{
      this.options = res.data
    })
  }
}
</script>

<style scoped>
.img_box{
  display: flex;
  flex-direction: row;
}
.img_box .delete_icon{
  position: absolute;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 1.25rem;
  left: 4.0625rem;
  top: 4.0625rem;
}
.img_box img{
  width: 9.375rem;
  height: 9.375rem;
  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
}
</style>