<template>
  <div id="app">
    <LoginTpl></LoginTpl>
    <router-view></router-view>
  </div>
</template>

<script>
import LoginTpl from "@/components/LoginTpl";
export default {
  name: 'App',
  components:{
    LoginTpl
  }
}
</script>
<style>
*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}
</style>