<template>
  <div class="pay_content">
    <div class="school_name">
      <div class="title_blue">{{this.$store.state.select_college_name}}</div>
      <div class="title_blue">{{this.$store.state.select_college_english_name}}</div>
    </div>
    <div class="tips">该院校有{{this.$store.getters.getAccountLength}}个收款账户，请仔细核对后再选择账户汇款！</div>

    <div :class="{account_info_box:true,box_active:account_select_id===account.id}" v-for="(account,index) in this.$store.state.account_list" :key="account.id" @click="selectAccount(account.id,index)">
      <div class="item" v-if="account.account_name">
        <div class="left">收款账户名</div>
        <div class="right">{{account.account_name}}</div>
      </div>
      <div class="item">
        <div class="left">收款账户号</div>
        <div class="right">{{account.account_num}}</div>
      </div>
      <div class="item" v-if="account.swift_code">
        <div class="left">SWIFT Code</div>
        <div class="right">{{account.swift_code}}</div>
      </div>
      <div class="item" v-if="account.bsb_code">
        <div class="left">BSB Number</div>
        <div class="right">{{account.bsb_code}}</div>
      </div>

    </div>
    <div class="tips_two">
      <div class="left">无该院校信息？</div>
      <router-link class="right" to="/add">去添加院校账户信息</router-link>
    </div>
    <div v-if="this.$store.state.account_select_id!==''">
      <div class="title_blue" style="margin-top: 6.875rem">填写缴费金额</div>
      <div class="tips_three">受汇率波动影响，具体支付金额以实际付款时为准</div>
      <div class="small_title">汇款金额</div>
      <div class="pay_num">
        <el-input :value="this.$store.state.price" @input="changePrice" @blur="checkMoney" type="number" />
        <span style="width: 35rem;display: inline-block;height: 7.3125rem;line-height: 7.3125rem;font-size: 2.5rem">{{this.$store.getters.getCurrencyName}}({{this.$store.getters.getCurrencyCode}})</span>
      </div>
      <div class="small_title" style="display: flex;flex-direction: row;align-items: center">
        <div>实时汇率</div>
        <div style="color: #008cd6">1{{this.$store.getters.getCurrencyCode}}={{this.$store.getters.getCurrencyRate}} CNY</div>
      </div>
      <div class="title_blue" style="margin-top: 5rem">实际支付金额</div>
      <div class="price_box" style="margin: 3.125rem 0rem">
        <div class="item">
          <div class="left">平台服务费</div>
          <div class="right">{{this.$store.state.serve_price}}人民币</div>
        </div>
        <div class="item">
          <div class="left">足额到账费</div>
          <div class="right">{{this.$store.state.full_price}}人民币</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div class="left">需支付金额</div>
          <div class="right" style="color: #008cd6">{{this.$store.getters.getTotalPrice}}人民币</div>
        </div>
      </div>
      <div class="title_blue">付款用途</div>
      <div class="select_box">
        <el-select placeholder="请选择" :value="this.$store.state.pay_use" @change="changePayUse">
          <el-option
              v-for="ii in this.$store.state.pay_use_options"
              :key="ii.id"
              :value="ii.name">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" class="next_btn" @click="nextSept">下一步</el-button>
    </div>
  </div>
</template>

<script>
import {req} from "@/apis/http";

export default {
  name: "OneTpl",
  data(){
    return{
      account_info_box:'account_info_box',
      account_select_id:'',
      price:'',
    }
  },
  methods:{
    changePayUse:function (value){
      this.$store.dispatch('updatePayUse',value)
    },
    nextSept:function (){
      let {account_select_id,price,pay_use} = this.$store.state
      if (!account_select_id){
        this.$message.error('请选择收款账户')
        return false
      }
      if (!price){
        this.$message.error('请填写汇款金额')
        return false
      }
      if (!pay_use){
        this.$message.error('请选择付款用途')
        return false
      }
      this.$store.dispatch('nextSeptAction',2)
    },
    selectAccount:function (id,index){
      this.account_select_id = id
      this.$store.dispatch('setAccountId', {id:id,index:index})
    },
    changePrice:function (price){
      this.$store.dispatch('updatePriceAction',price)
    },
    checkMoney:function (){
      let {price} = this.$store.state
      if (!this.checkAmount(price)){
        this.$alert('金额格式错误，请重新输入', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            console.log(action)
          }
        });
        this.$store.dispatch('updatePriceAction',0)
      }
    },
    checkAmount:function(price) {
      var exp = /^(([1-9]\d*)|\d)(\.\d{1,2})?$/;
      if(!exp.test(price)){
        return false
      }else {
        return true;
      }
    }
  },
  created() {
    window.scrollTo(0,0)
    //检查当前登录状态
    req('get','/api/token/check').then(res=>{
      if (res.code!==1){
        this.$router.push('/')
      }
    })
    req('get','/api/common/payUse').then(res=>{
      if (res.code!==1){
        this.$store.dispatch('updateLoginCardStatusAction',true)
      }else{
        this.$store.dispatch('initPayUseOptions',res.data)
      }
    })
  }
}
</script>

<style scoped>

</style>