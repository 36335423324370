<template>
  <div class="pay_main">
    <HeaderTpl></HeaderTpl>
    <div style="width: 100%;height: 5rem"></div>
    <!--    确认金额-->
    <OneTpl  v-if="this.$store.state.active===1"></OneTpl>
    <!--    填写信息-->
    <TwoTpl v-if="this.$store.state.active===2"></TwoTpl>
    <!--    确认信息-->
    <ThreeTpl v-if="this.$store.state.active===3"></ThreeTpl>
    <!--    开始缴费-->
    <FourTpl v-if="this.$store.state.active===4"></FourTpl>
    <StepsTpl></StepsTpl>
    <FooterTpl></FooterTpl>
  </div>
</template>

<script>
import {req} from "@/apis/http";
import HeaderTpl from "../components/HeaderTpl";
import StepsTpl from "@/components/StepsTpl";
import OneTpl from "@/components/OneTpl";
import TwoTpl from "@/components/TwoTpl";
import ThreeTpl from "@/components/ThreeTpl";
import FourTpl from "@/components/FourTpl";
import FooterTpl from "@/components/FooterTpl.vue";


export default {
  name: "PayView",
  components:{
    HeaderTpl,
    StepsTpl,
    OneTpl,
    TwoTpl,
    ThreeTpl,
    FourTpl,
    FooterTpl
  },
  data() {
    return {

    }
  },
  methods:{

  },
  created() {
    //检查当前登录状态
    req('get','/api/token/check').then(res=>{
      if (res.code!==1){
        this.$message.error(res.msg)
        this.$router.push('/')
      }
    })
  },
  mounted() {
    let {college_select_id,from_type,account_id} = this.$store.state
    if (from_type===1&&college_select_id){
      req('get','/api/college/account?college_id='+college_select_id+'&from_type='+from_type).then(res=>{
        this.$store.dispatch('addAccountListAction',res.data)
      })
    }else if (from_type===2&&account_id){
      req('get','/api/college/account?account_id='+account_id+'&from_type='+from_type).then(res=>{
        this.$store.dispatch('addAccountListAction',res.data)
      })
    }else{
      return false
    }

  }
}
</script>

<style scoped>
  @import "/src/css/pay.css";
</style>