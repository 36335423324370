<template>
  <div class="main">
    <HeaderTpl></HeaderTpl>
    <div style="width: 100%;height: 5rem"></div>
    <div class="center_title">
      <div class="t1">留学缴费</div>
      <div class="t2">足额缴费，院校直达</div>
    </div>
    <div class="search_box">
      <el-autocomplete
          class="search"
          v-model="keywords"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入缴费院校的中英文全称或简称"
          @select="handleSelect"
          @input="valueChange"
      >
        <el-button slot="append" icon="el-icon-search" @click="toPay"></el-button>
        <template slot-scope="{ item }">
          <div v-if="item.id" class="tt">
            <div class="name">{{ item.english_name }}</div>
            <span class="addr">{{ item.college_name }}</span>
          </div>
          <router-link to="/add" v-else class="tt2">
            <div class="search_tips_text_1">
              无该院校信息？
            </div>
            <div class="search_tips_text_2">
              去添加院校账户信息
            </div>
          </router-link>
        </template>
      </el-autocomplete>
    </div>
    <FooterTpl></FooterTpl>
  </div>
</template>

<script>

import HeaderTpl from "../components/HeaderTpl"
import FooterTpl from "@/components/FooterTpl.vue";
import {req} from "@/apis/http";

export default {
  name: "indexView",
  components:{
    HeaderTpl,
    FooterTpl
  },
  data(){
    return {
      keywords: ''
    }
  },
  methods:{
    querySearchAsync(queryString,cb) {
      req('post','/api/college/lists', {keywords:queryString},false).then(res=>{
        this.$store.dispatch('addCollegeListAction',res.data)
        cb(res.data);
      })
    },
    handleSelect(item) {
      this.keywords = item.college_name
      this.$store.dispatch('selectCollegeIdAction',item.id)
      this.$store.dispatch('selectCollegeNameAction', {college_name:item.college_name,english_name:item.english_name})

    },
    toPay(){
      let {college_select_id} = this.$store.state
      if (!college_select_id){
        this.$message.error('请先在搜索列表中选择院校');
        return false;
      }
      //检查当前登录状态
      req('get','/api/token/check').then(res=>{
        if (res.code!==1){
          localStorage.clear();
          this.$message.error(res.msg)
          this.$store.dispatch('updateLoginCardStatusAction',true)
        }else{
          this.$router.push({path: '/pay' })
        }
      })
    },
    valueChange(){
      this.$store.dispatch('selectCollegeIdAction','')
    }
  },
  created() {
    let token = localStorage.getItem('token')
    //没有令牌，显示登录卡片
    if (!token){
      this.$store.dispatch('updateLoginCardStatusAction',true)
    }
    this.$store.dispatch('initAction',{})
  },
  mounted() {
    req('post','/api/college/lists','').then(res=>{
      this.$store.dispatch('addCollegeListAction',res.data)
    })
  }
}
</script>

<style scoped>
  .search_box .el-input__inner{
    height: 7.5rem !important;
    color: red;
  }
  .search_box .el-input-group__append{
    background: #FFFFFF !important;
  }
  .tt{
    height: 7.5rem;
    box-sizing: border-box;
    padding: 1.25rem 0.625rem;
    border-bottom: 0.1875rem solid #b5b5b5;
  }
  .tt2{
    height: 7.5rem;
    box-sizing: border-box;
    padding: 1.25rem 0.625rem;
    border-bottom: 0.1875rem solid #b5b5b5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
  }
  .name {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1.75rem;
    color: #898989;
    height: 2.5rem;
  }
  .addr {
    width: 100%;
    font-size: 1.5rem;
    color: #b4b4b4;
    padding-top: 1.25rem;
    height: 2.5rem;
  }
  .search_tips_text_1{
    font-size: 1.5rem;
    color: #b4b4b4;
    padding-top: 1.25rem;
    height: 2.5rem;
  }
  .search_tips_text_2{
    font-size: 1.5rem;
    color: #008cd6;
    padding-top: 1.25rem;
    height: 2.5rem;
  }


  @import "/src/css/index.css";

</style>