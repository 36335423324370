import axios from "axios";
const base = 'https://payapi.gzlxeh.com/'
// const base = 'http://www.lxeh.test'

//修改默认配置
axios.defaults.baseURL = base

const uploadPath = base+'/api/user/uploadFile'

axios.defaults.timeout = 5000

// 登录请求方法
const loginreq = (method, url, params) => {
    return axios({
        method: method,
        url: url,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: params,
        traditional: true,
        // transformRequest: [
        //     function(data) {
        //         let ret = ''
        //         for (let it in data) {
        //             ret +=
        //                 encodeURIComponent(it) +
        //                 '=' +
        //                 encodeURIComponent(data[it]) +
        //                 '&'
        //         }
        //         return ret
        //     }
        // ]
    }).then(res => res.data);
};
// 通用公用方法
const req = (method, url, params, has_token=true) => {
    let headers ={}
    if (has_token){
        headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.getItem('token')
        }
    }else{
        headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    return axios({
        method: method,
        url: url,
        headers: headers,
        data: params,
        traditional: true,
        transformRequest: [
            function(data) {
                let ret = ''
                for (let it in data) {
                    ret +=
                        encodeURIComponent(it) +
                        '=' +
                        encodeURIComponent(data[it]) +
                        '&'
                }
                return ret
            }
        ]
    }).then(res => {
        return res.data
    });
};

//文件上传地址


export {
    loginreq,
    req,
    uploadPath,
    base
}